import { useState } from "react";
import { Upload } from "lucide-react";

const PassportUploader = () => {
  const [image, setImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Validate file size (Max: 1MB)
    if (file && file.size > 1024 * 1024) {
      alert("File size exceeds 1MB. Please upload a smaller file.");
      return;
    }

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
    }
  };

  const resetUpload = () => {
    setImage(null); // Reset image to allow new upload
  };

  return (
    <div className="passport-container" onClick={resetUpload}>
      {/* Image Preview OR Upload Box */}
      {image ? (
        <img src={image} alt="Passport Preview" className="passport-preview" />
      ) : (
        <label htmlFor="passportPhoto" className="passport-upload-label">
          <Upload className="upload-icon" />
          <span style={{ fontSize: "12px" }}>
            Upload Passport Photo (Max: 1MB)
          </span>
        </label>
      )}

      {/* Hidden File Input */}
      <input
        type="file"
        id="passportPhoto"
        name="photo"
        accept="image/*"
        required
        onChange={handleImageUpload}
        className="hidden-input"
      />
    </div>
  );
};

export default PassportUploader;
